.serie-info-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
}

.serie-info-left-container {
    width: 66%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 17px;
}


.serie-info-input-container {
    width: 100%;
    margin: 10px 0;
}

.serie-info-checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    width: 100%;
}

