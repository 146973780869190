.light-scheme {
    --normal: white;
    --hover: white
}

.dark-scheme {
    --normal: black;
    --hover: var(--orange)
}


.MuiInputLabel-root {
    color: var(--normal);
}

.MuiInputLabel-root.Mui-focused {
    color: var(--hover)
}

.MuiTextField-root:hover > .MuiInputLabel-root {
    color: var(--hover)
}

.MuiInput-root::before {
    border-color: var(--normal);
}

.MuiInput-root {
    color: var(--normal);
}

.MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid var(--hover);
}

.MuiInput-root::after {
    border-color: var(--hover);
}

.MuiIconButton-root {
    color: var(--normal);
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: var(--normal) !important;
}
