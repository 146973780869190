.reports-page-main-div {
    width: 100%;
    /* height: calc(100vh - 80px); */
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    min-width: 900px;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--background);
}

.reports-page-top-div {
    height: 125px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 25px;
    background: linear-gradient(to top, var(--dark-blue), var(--light-blue));
    box-shadow: 0 0 10px 3px rgba(0,0,0,0.5);
    width: 100%;
}

.reports-page-top-info-main-div {
    display: flex;
}

.reports-page-top-add {
    width: 180px;
    display: flex;
    color: white;
    justify-content: space-around;
    align-items: center;
    margin: 0px 20px;
    height: 75px;
}

.reports-page-top-info {
    width: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    margin: 0px 20px;
    height: 75px;
}

.reports-page-top-info-title {
    font-size: 18px;
}

.reports-page-top-info-value {
    font-size: 36px;
}

.reports-page-bottom-div {
    width: 100%;
    height: fit-content;
    min-height: calc(100vh - 205px);
}

.bottom-div-reports {
    display: flex;
    height: 100%;
    overflow-x: hidden;
    flex-direction: column;
}

.reports-views-charts {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.reports-misc-charts {
    height: 330px;
    padding: 25px;
    width: 100%;
    box-shadow: 0px 5px 17px -5px rgba(0,0,0,0.5);
    margin-bottom: 15px;
}

.views-charts-events {
    width: 100%;
    margin-bottom: 15px;
}

.views-charts-episodes {
    width: 100%;
    margin-bottom: 15px;
}