.settings-mui-dialog-container > .MuiDialog-container > .MuiPaper-root {
    border-radius: 20px;
    background-color: transparent;
    min-height: 600px;
    min-width: 800px;
}

.settings-dialog-content {
    height: 600px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
}

.settings-dialog-header {
    height: 10%;
    padding: 0 20px;
    background-image: linear-gradient(to bottom, var(--light-blue) 0%, var(--dark-blue) 100%);
    display: flex;
}

.settings-dialog-title {
    flex: 1 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.settings-dialog-body {
    width: 100%;
    height: 90%;
    background-color: var(--background);
    display: flex;
}

.settings-body-menu {
    height: 100%;
    width: 25%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

.settings-body-right-content {
    height: 100%;
    flex: 1 1;
}

.settings-menu-button {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
}

.settings-menu-button.active {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    background-color: var(--light-blue);
    color: white;
}

.settings-menu-button:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    background-color: var(--light-blue);
    color: white;
}

.body-right-roles {
    height: 100%;
    width: 100%;
    padding: 10px 20px 0px 20px;
}