.detailed-panel-main-div {
    display: flex;
    gap: 50px;
}

.detailed-panel-chips {
    width: fit-content;
    padding: 0 25px;
}

.detailed-panel-description {
    flex: 1 1;
    padding: 0 25px;
}

.detailed-panel-info {
    width: fit-content;
    padding: 0 25px;
}

.detailed-panel-chip {
    max-width: 300px;
}

.detailed-panel-chip > p {
    font-weight: 700;
}

.detailed-panel-chip span {
    font-weight: 300;
}