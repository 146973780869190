.delete-dialog-content {
    color: white;
    padding: 10px 24px;
    background-image: linear-gradient(to top, var(--light-blue) 0%, var(--dark-blue) 100%);
    font-size: 18px;
    user-select: none;
}

.delete-dialog-action {
    padding: 15px 15px !important;
    background-color: var(--background);
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 175px;
}

.delete-dialog-buttons-div {
    width: 100%;
    height: 25%;
    display: flex;
    justify-content: space-between;
    user-select: none;
}

.delete-dialog-text-div {
    width: 100%;
    flex: 1 1; 
    padding-left: 5px;
    white-space: pre-line;
    font-size: 13px;
}

.delete-dialog-button {
    appearance: none;
    height: 30px;
    border: none;
    border-radius: 2rem;
    padding: 0 10px;
    background-color: var(--light-blue);
    color: white;
    outline-color: var(--pink-soft);
    cursor: pointer;
}

.delete-dialog-button:disabled {
    appearance: none;
    height: 30px;
    border: none;
    border-radius: 2rem;
    padding: 0 10px;
    background-color: gray;
    color: white;
    cursor: default;
    outline-color: var(--pink-soft);
}

.delete-dialog-button:disabled:hover {
    background-color: gray;
}

.delete-dialog-button:first-child {
    margin-right: 10px;
}

.delete-dialog-button:hover {
    background-color: var(--light-purple);
}