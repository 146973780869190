.add-about-dialog-content {
    height: 850px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
}

.add-about-dialog-content > div {
    background-color: var(--background);
}

.add-about-header {
    height: 10%;
    padding: 0 20px;
    background-image: linear-gradient(to bottom, var(--light-blue) 0%, var(--dark-blue) 100%);
    color: white;
    font-size: 25px;
    display: flex;
    align-items: center;
}

.add-about-stepper {
    height: 15%;
    padding: 10px 0 5px 0;
    min-width: 90px
}

.add-about-inputs {
    flex: 1 1;
}

.add-about-action {
    height: 10%;
    display: flex;
    justify-content: space-between;
    padding: 0 50px;
    min-width: 60px;
    align-items: center;
    user-select: none;
}

.MuiStepLabel-label {
    margin-top: 5px !important;
}

.MuiStepLabel-labelContainer > .Mui-active {
    text-shadow: 0 0 1px black;
}

.stepper-buttons {
    appearance: none;
    border: none;
    background-color: transparent;
    font-size: 18px;
    color: var(--orange);
    cursor: pointer;
}

.stepper-buttons:disabled {
    text-shadow: none;
    color: grey;
    cursor: default;
    
}

.stepper-buttons:disabled:hover {
    text-shadow: none;
}

.stepper-buttons:hover {
    text-shadow: 0 0 1px var(--orange);
}

.add-about-mui-dialog-container > .MuiDialog-container > .MuiPaper-root {
    border-radius: 20px;
    background-color: transparent;
    min-height: 600px;
    min-width: 800px;
}