.alert-div-show {
    width: 100%;
    height: 50px;
    opacity: 1;
    display: flex;
    margin-top: 0;
    margin-bottom: 5px;
    justify-content: center;
    transition: all 0.3s ease-in-out;
}

.alert-div-hide {
    width: 100%;
    height: 0px;
    opacity: 0;
    margin-top: -80px;
    margin-bottom: 80px;
    display: flex;
    justify-content: center;
    transition: all 0.3s ease-in-out;
}