.topicsAndIndustries-main-div {
    width: 100%;
    height: fit-content;
    min-height: 900px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.topics-table-div {
    width: 600px;
    min-height: 400px;
    display: flex;
    justify-content: center;
}

.industries-table-div {
    width: 600px;
    min-height: 400px;
    display: flex;
    justify-content: center;
}