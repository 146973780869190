.dashboard-card {
    width: 250px;
    height: 250px;
    margin: 25px 5px 0 5px;
    padding: 25px;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    user-select: none;
}

.dashboard-card:hover {
    background: linear-gradient(to top, var(--dark-blue), var(--light-blue));
    box-shadow: 0 0 15px 0px rgba(0,0,0,0.5) ;
    border-radius: 15px;
    transform: scale(1.1);
}

.dashboard-card-title {
    color: white;
    font-size: 18px;
    text-align: center;
    margin-bottom: 10px;
}

.dashboard-card-text {
    color: white;
    font-size: 50px;
    text-align: left;
    font-weight: 600;
}

.dashboard-card-info-div {
    height: 60%;
    width: 100%;
}

.dashboard-card-graph {
    width: 100%;
    height: 40%;
}