.misc-report-main-div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
}

.topics-per-episode-div {
    width: 45%;
    min-width: 450px;
}

.industries-per-episode-div {
    width: 45%;
    min-width: 450px;
}

