.small-episode-card-container {
    position: relative;
    width: 225px;
    height: 75px;
    margin: 5px 0;
    transition: all 0.2s ease-in-out;
    border-radius: 15px;
    cursor: pointer;
    box-shadow: 2px 2px 6px 0px rgb(0 0 0 / 50%);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow-y: hidden;
    
}

.small-episode-card-container:hover {
    width: 235px;
}

.small-episode-card-gradient {
    padding: 5px;
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 15px;
    background-color: rgb(0 0 0 / 25%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.small-episode-card-title {
    color: white;
    font-size: 13px;
    text-shadow: 1px 1px 1px rgb(0 0 0 / 100%);
    text-align: center;
    max-width: 215px;
    font-weight: 600;
}