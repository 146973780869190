.custom-table-body {
    width: 100%;
    height: 100%;
    border-radius: 2rem;
    text-align: center;
    color: white;
    user-select: none;
}

.action-icon:hover {
    cursor: pointer;
    transform: scale(1.1);
}