.filter-panel-main-div.show {
    height: 100%;
    width: 350px;
    background-color: var(--dark-blue);
    transition: all 0.5s ease-in-out;
}

.filter-panel-main-div {
    height: 100%;
    width: 0px;
    background-color: var(--dark-blue);
    transition: all 0.5s ease-in-out;

}

.filter-panel-fixed-div.show {
    position: fixed;
    width: 350px;
    right: 0px;
    overflow-y: auto;
    height: 80vh;
    display: flex;
    background-color: var(--dark-blue);
    align-items: flex-start;
    transition: all 0.5s ease-in-out;
}

.filter-panel-fixed-div {
    position: fixed;
    width: 350px;
    right: -350px;
    height: 80vh;
    background-color: var(--dark-blue);
    display: flex;
    overflow-y: auto;
    align-items: flex-start;
    transition: all 0.5s ease-in-out;
}

.filter-panel-handler {
    position: fixed;
    top:53%;
    height: 125px;
    width: 30px;
    margin-left: -30px;
    padding-left: 10px;
    background-color: var(--dark-blue);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
}

.filter-panel-inner-div {
    height: fit-content;
    width: 100%;
    /* background-image: linear-gradient(to bottom, var(--dark-blue), var(--light-blue)); */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.5s ease-in-out;
}

.filter-view {
    max-height: 100px;
    transition: all 0.3s ease-in-out;
}

.filter-state {
    max-height: 100px;
    transition: all 0.3s ease-in-out;
}

.filter-filter {
    max-height: 600px;
    margin-bottom: 25px;
    transition: all 0.5s ease-in-out;
}

.filter-view,
.filter-state,
.filter-filter {
    color: #1976d2;
    height: fit-content;
    width: 100%;
    border-bottom: 1px solid #174779;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 0;
    overflow: hidden;
}

.filter-view.collapsed,
.filter-state.collapsed {
    max-height: 52px;
    transition: all 0.3s ease-in-out;
}

.filter-filter.collapsed {
    max-height: 52px;
    transition: all 0.5s ease-in-out;
}

.filter-view.collapsed:hover,
.filter-state.collapsed:hover,
.filter-filter.collapsed:hover {
    background-color: rgba(177,177,177,0.1);
}

.filter-panel-title {
    margin-bottom: 10px;
    width: 100%;
    cursor: pointer;
    text-align: center;
    user-select: none;
    font-size: 18px;
}

.filter-filter-inner-div {
    margin: 0 0 10px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.filter-filter-inner-subdiv {
    margin: 0 0 20px 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.filter-slider-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.filter-clear-all-filter {
    border: 1px solid #1976d2;
    border-radius: 2rem;
    width: 45%;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    margin-top: 15px;
}

.filter-clear-all-filter:hover {
    background-color: var(--orange);
    color: white;
    border-color: transparent;
    user-select: none;
}
