.search-main-div {
    /* border: 1px solid red; */
    width: 80%;
    height: 60px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.order-input {
    display: flex;
    align-items: center;
}