.about-info-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
}

.about-info-left-container {
    width: 35%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 17px;
    align-items: center;
}

.about-info-right-container {
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    align-items: center;
    justify-content: space-around;
}

.about-preview-container {
    height: 65%;
    width: 100%;
    border: 1px dashed gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 10px;
    overflow-y: auto;
}

.about-preview-title {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 25px;
}

.about-preview-text {
    font-size: 14px;
    font-weight: 400;
    white-space: pre-wrap;
}

.about-info-input-container {
    width: 100%;
    margin: 10px 0;
}

.about-info-checkbox-container {
    height: 30%;
    display: flex;
    align-items: flex-start;
    width: 100%;
}


.about-info-autocomplete-div {
    margin-top: 20px;
    width: 80%;
}

.about-gradient {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.about-misc-style {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.about-color-picker-color {
    width: 36px;
    height: 14px;
    border-radius: 2px;
}

.about-color-picker-swatch {
    padding: 5px;
    background: '#fff';
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    cursor: pointer;
}

.about-color-picker-popover {
    position: absolute;
    z-index: 2;
}

.about-color-picker-cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.color-picker-div {
    display: flex;
    /* justify-content: center; */
}

.color-picker-div > p {
    margin-right: 10px;
}