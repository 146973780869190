.serie-card-main-div {
    height: 350px;
    width: 250px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 2px 2px 4px 1px rgba(0,0,0,0.5);
    transition: all 0.2s ease-in-out;
    padding: 10px;
}

.serie-card-main-div:hover {
   transform: translateY(-3px); 
}

.serie-card-title {
    position: inherit;
    z-index: 1;
    color: white;
    text-align: center;
    font-weight: 700;
    font-size: 22px;
    text-shadow: 1px 1px black;
}

.serie-card-event-counter {
    position: inherit;
    z-index: 1;
    display: flex;
    gap: 5px;
    align-items: center;
    color: white;
    margin-bottom: 20px;
}

.serie-card-main-div > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.serie-card-action-div {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    color: white;
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 10px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
}

.serie-card-action-div svg{
    cursor: pointer;
}