.faq-info-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
}

.faq-info-left-container {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.faq-info-input-container {
    width: 100%;
    margin: 15px 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}