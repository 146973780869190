.login-page-main-div {
    width: 100vw;
    height: 100vh;
    /* background-image: linear-gradient(-45deg,var(--base1),#ee7752, #e73c7e, var(--base2)); */
    background-image: linear-gradient(-45deg,var(--base1),var(--orange), var(--pink-soft), var(--base2));
    background-size: 400% 400%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: background-gradient 25s infinite linear;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 250px;
    width: 350px;
    margin-top: 50px;
}

.login-button {
    width: 120px;
    height: 30px;
    border: 1px solid white;
    border-radius: 5px;
    color: white;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    font-size: 16px;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
}

.login-button:hover {
    border-radius: 15px;
}

.login-forgotpass-div {
    width: 100%;
    justify-content: flex-start;
    color: white;
    cursor: pointer;
    text-decoration: underline;
    margin-top: -20px;
    font-size: 14px;
}

.login-forgotpass-div > p {
    transition: all 0.3s ease-in-out;
} 

.login-forgotpass-div > p:hover {
    font-size: 15px;
} 

.forgot-pass-button {
    appearance: none;
    background-color: transparent;
    outline: none;
    width: 50px;
    height: 25px;
    font-size: 18px;
    font-weight: 400;
    color: var(--orange);
    margin: 0 15px 15px 0;
    border: none;
    cursor: pointer;
}

.forgot-pass-button:disabled {
    border: none;
    color: var(--grey);
    font-weight: 200;
    cursor: not-allowed;
}

@keyframes background-gradient {
    0% { background-position: 0% 50%;}
    50% { background-position: 100% 50%;}
    100% { background-position: 0% 50%;}
}