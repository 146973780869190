.forgot-password-main-div {
    width: 100vw;
    height: 100vh;
    /* background-image: linear-gradient(-45deg,var(--base1),#ee7752, #e73c7e, var(--base2)); */
    background-image: linear-gradient(-45deg,var(--base1),var(--orange), var(--pink-soft), var(--base2));
    background-size: 400% 400%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: background-gradient 25s infinite linear;
}

.forgot-password-card-div {
    width: 500px;
    height: fit-content;
    padding: 20px 0;
    background-color: azure;
    border-radius: 10px;
    box-shadow: 5px 5px 13px 0px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.forgot-password-card-div > p {
    margin: 10px 0;
    font-size: 22px;
    font-weight: 600;
    color: var(--pink-soft);
}

@keyframes background-gradient {
    0% { background-position: 0% 50%;}
    50% { background-position: 100% 50%;}
    100% { background-position: 0% 50%;}
}