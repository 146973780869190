.activity-main-container {
    height: fit-content;
    color: black;
    margin: 10px 10px;
    display: flex;
    min-height: 30px;
    border-radius: 10px;
    background: linear-gradient(0.25turn, var(--orange) 70%, var(--orange-soft) 100%);
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);

}

.activity-card-user::after {
    content:' '
}

.activity-card-user {
    font-weight: 700;
}

.activity-card-message {
    display: flex;
    align-items: center;
    width: 85%;
    font-size: 12px;
    padding-left: 5px;
    color: white;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.activity-card-timestamp {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: white;
    font-size: 12px;
    width: 15%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.activity-main-container:hover {
    background: var(--light-blue);
}