.event-more-info-dialog-content {
    height: 750px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
}

.event-more-info-dialog-header {
    height: 10%;
    padding: 0 20px;
    background-image: linear-gradient(to bottom, var(--light-blue) 0%, var(--dark-blue) 100%);
    display: flex;
}

.event-more-info-dialog-info-wrapper {
    height: 90%;
    display: flex;
    align-items: center;
    background-color: var(--background);
}

.event-more-info-dialog-episodes-div {
    height: 100%;
    width: 30%;
    padding: 10px 5px;
    overflow-y: auto;
}

.event-more-info-dialog-episodes-scroll-div {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.event-more-info-dialog-info-div {
    height: 80%;
    width: 100%;
    overflow-y: auto;
}

.event-more-info-dialog-images {
    height: 20%;
    width: 100%;
    background-color: var(--background);
    flex-direction: row-reverse;
}

.event-dialog-title {
    flex: 1 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.event-dialog-actions {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: white;
}

.event-dialog-actions > * {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.event-dialog-actions > *:hover {
    transform: scale(1.1);
}

.event-dialog-chips {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
}

.list-item-div {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 50px;
}

.event-more-info-chips-div {
    display: flex;
    justify-content: space-around;
    width: 100%;
    user-select: none;
    padding: 20px;
}

.event-more-info-dialog-info-div::-webkit-scrollbar {
    width: 10px;
}

.event-more-info-dialog-info-div::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
}

.event-more-info-dialog-info-div::-webkit-scrollbar-track:hover {
    background-color: rgba(0,0,0,0.1);
}

.event-more-info-dialog-info-div::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: var(--orange-soft);
    background-clip: content-box;
}

.event-more-info-dialog-info-div::-webkit-scrollbar-thumb:hover{
    border: 3px solid transparent;
    background-color: var(--orange-soft);
}

.event-more-info-other-info {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    color: rgba(0, 0, 0, 0.6);
}

.event-more-info-images-container {
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    flex: 1 1;
}

.event-more-info-images-container > img {
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.event-more-info-images-container > img:hover {
    transform: scale(1.1);
}

.event-more-info-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    height: 100%;
}