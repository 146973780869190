.faq-main-div {
    width: 100%;
    height: fit-content;
    min-height: 900px;
    display: flex;
    justify-content: center;
}

.faq-table-div {
    width: 90%;
    min-height: 400px;
    display: flex;
    justify-content: center;
}