.roles-settings-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.roles-settings-title {
    height: 10%;
    width: 100%;
}

.roles-settings-bottom {
    display: flex;
    flex: 1 1;
    width: 100%;
    height: 80%;
    box-shadow: inset 6px 6px 20px 0px rgb(0 0 0 / 20%), inset 1px 5px 20px 5px rgb(0 0 0 / 14%), inset 5px 5px 5px 0px rgb(0 0 0 / 12%);
    border-radius: 7px; 
}

.roles-selector {
    height: 100%;
    width: 30%;
    overflow-y: auto;
    border-right: 1px solid rgba(0,0,0,0.2);
}

.roles-info {
    flex: 1 1;
    height: 100%;
    overflow-y: auto;
}

.role-card {
    width: 100%;
    border-radius: 5px;
    height: fit-content;
    padding: 0 10px;
    text-align: center;
    min-height: 40px;
    word-break: break-all;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.role-card.active {
    background-color: var(--orange-soft);
    color: white;
    border-radius: 5px;
    width: 100%;
    height: fit-content;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.role-card:hover {
    background-color: var(--orange-soft);
    color: white;
}

.role-permissions-main-div {
    height: fit-content;
    position: relative;
}

.role-permissions-title {
    font-size: 26px;
    word-break: break-all;
    max-width: 330px;
}

.role-permissions-title-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px 0 15px;
}

.role-permissions-actions {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.role-permissions-actions > svg {
    color: var(--light-blue);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.role-permissions-actions > svg:hover {
    color: var(--orange-soft);
}

.roles-category {
    display: flex;
    flex-direction: column;
    margin: 10px 0 10px 20px;
}

.save-changes-btn {
    width: 125px;
    height: 35px;
    background-color: red;
    margin: 10px;
}

.role-category-title {
    font-weight: 500;
}

.roles-settings-title {
    font-size: 22px;
    font-weight: 600;
    margin-left: 5px;
}