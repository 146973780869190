.trash-page-main-div {
    width: 100%;
    /* height: calc(100vh - 80px); */
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    min-width: 900px;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--background);
}

.trash-page-top-div {
    height: 125px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 25px;
    background: linear-gradient(to top, var(--dark-blue), var(--light-blue));
    box-shadow: 0 0 10px 3px rgba(0,0,0,0.5);
    width: 100%;
}


.trash-page-top-add {
    width: 180px;
    display: flex;
    color: white;
    justify-content: space-around;
    align-items: center;
    margin: 0px 20px;
    height: 75px;
}

.trash-page-top-info {
    width: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    margin: 0px 20px;
    height: 75px;
}

.trash-page-top-info-title {
    font-size: 18px;
    text-align: center;
}

.trash-page-top-info-value {
    font-size: 36px;
}

.trash-page-bottom-div {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 10px;
    height: fit-content;
    min-height: calc(100vh - 205px);
}

.bottom-div-trash {
    flex: 1 1;
    max-width: 1400px;
    height: fit-content;
    margin-top: 25px;
    min-height: 400px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.bottom-div-trash.hidden {
    display: none;
}

.bottom-div-trash-wrapper {
    flex: 1 1;
    height: fit-content;
    padding: 15px 25px 25px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.recycle-remove-all-btn {
    margin-top: 25px;
    height: 50px;
    width: fit-content;
    padding: 0 10px;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
    border: 1px solid var(--light-red);
    background-color: var(--background);
}

.recycle-remove-all-btn:hover {
    background-color: var(--light-red);
    color: white;
}