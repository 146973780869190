.user-profile-more-info-dialog-content {
    height: 450px;
    width: 600px;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 !important;
}

.user-profile-more-info-dialog-header {
    height: 10%;
    padding: 0 20px;
    background-image: linear-gradient(to bottom, var(--light-blue) 0%, var(--dark-blue) 100%);
    display: flex;
}

.user-profile-more-info-dialog-info-wrapper {
    height: 90%;
    display: flex;
    align-items: center;
    background-color: var(--background);
}

.user-profile-more-info-dialog-picture-div {
    height: 100%;
    width: 45%;
    padding: 10px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.user-profile-more-info-dialog-picture {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 10px;
    filter: drop-shadow(2px 4px 6px black);
    cursor: pointer;
}

.user-profile-dialog-title {
    flex: 1 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.user-profile-dialog-title > p {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}


.user-profile-more-info-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    height: 100%;
    position: relative;
}

.user-profile-mui-dialog-container > .MuiDialog-container > .MuiPaper-root {
    border-radius: 20px;
    background-color: transparent;
    min-height: 300px;
    min-width: 600px;
    width: 600px;
}

.user-profile-name {
    font-size: 16px;
    color: grey;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    word-break: break-word;
    padding: 0 15px;
}

.user-profile-email {
    font-size: 14px;
    color: grey;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    word-break: break-word;
    padding: 0 15px;
}

.userType-div-profile {
    width: fit-content;
    padding: 0 10px;
    height: 20px;
    border-radius: 10px;
    background-color: var(--light-blue);
    text-align: center;
    color: white;
    line-height: 20px;
    word-break: break-all;
    margin: 0 10px;
}

.userType-wrapper-div-profile {
    position: absolute;
    width: 100%;
    top: 200px;
    display: flex;
    justify-content: center;
    z-index: 20;
    overflow: hidden;
}

.user-profile-picture {
    object-fit: cover;
}

.user-profile-actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex: 1 1;
}

.user-profile-actions > div {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.user-profile-actions button {
    border-radius: 10px;
    width: fit-content;
    padding: 0 5px;
    height: 25px;
    outline: none;
    border-color: transparent;
    background-color: var(--light-blue);
    color: white;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}

.user-profile-actions button:hover {
    background-color: #827b83;
}

.user-profile-static-info {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    
}
.user-profile-info-common-info {
    margin-top: 10px;
    text-align: center;
    overflow-y: auto;
    height: 90px;
}

.user-profile-info-last-activity {
    margin: 10px 0;
    width: 100%;
}

.user-profile-info-meta {
    margin: 10px 0;
}

.user-profile-static-info.hidden {
    display: none;
}

.user-profile-static-editProfile {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 25px 10px 15px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.user-profile-static-editProfile.hidden {
    display: none;
}

.user-profile-static-changePassword {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 45px 10px 15px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-profile-static-changePassword.hidden {
    display: none;
}

.user-profile-meta {
    font-size: 12px;
    color: grey;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    word-break: break-word;
    padding: 0 15px;
}

.user-profile-activity-text {
    padding-left: 5px;
    font-size: 12px;
    margin-bottom: 5px;
}

.user-profile-activity-scrollable {
    overflow-y: auto;
    height: 200px;
}

.user-profile-change-pass-fields {
    width: 100%;
    height: 100%;
}

.user-profile-edit-fields {
    width: 100%;
    height: 100%;
}