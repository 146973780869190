.custom-toolbar {
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    width: 90px;
    top: 20px;
    right: 180px;
}

.custom-toolbar-btn {
    cursor: pointer;
}