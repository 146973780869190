.speaker-card-main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    max-width: 250px;
    height: 50px;
    background-color: var(--light-blue);
    border-radius: 10px;
    cursor: pointer;
    margin: 10px 0 15px 10px;
    transition: all 0.2s ease-in-out;
}

.speaker-card-text-div {
    font-size: 14px;
    margin-left: 15px;
    color: white;
    width: 100%;
}

.speaker-card-main-container:hover {
    width: 90%;
}