.speaker-review-main-container {
    height: 300px;
    overflow-y: auto;
}

.speaker-review-scrollable-div {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.speaker-review-label-div {
    display: flex;
    flex-direction: column;
    padding: 0 75px;
    margin: 15px 0;
    width: 100%;
    text-align: left;
}

.speaker-review-label {
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
}

.speaker-review-text {
    font-size: 14px;
    color:grey;
    white-space: pre-line;
}

.speaker-review-chips-div {
    display: flex;
    justify-content: space-around;
    width: 100%;
    user-select: none;
    padding: 20px;
}

.speaker-review-images-div {
    height: 150px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: 15px 0;
}

.speaker-review-images-div > img {
    border-radius: 15px;
}

.speaker-review-main-container::-webkit-scrollbar {
    width: 10px;
}

.speaker-review-main-container::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
}

.speaker-review-main-container::-webkit-scrollbar-track:hover {
    background-color: rgba(0,0,0,0.1);
}

.speaker-review-main-container::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: var(--orange-soft);
    background-clip: content-box;
}

.speaker-review-main-container::-webkit-scrollbar-thumb:hover{
    border: 3px solid transparent;
    background-color: var(--orange-soft);
}