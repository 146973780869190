.user-card-main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    max-width: 250px;
    height: 40px;
    background-color: var(--light-blue);
    border-radius: 10px;
    margin: 10px 10px 10px 20px;
    transition: all 0.2s ease-in-out;
}

.user-card-text-div {
    font-size: 14px;
    margin-left: 15px;
    color: white;
    width: 100%;
}