.info-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    column-gap: 30px;
    padding: 20px;
}

.info-container {
    flex: 1 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    gap: 10px;
}

.info-input-container {
    width: 100%;
    margin: 10px 0;
}

.info-checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    width: 100%;
    flex-wrap: wrap;
}

.info-date-picker-container {
    margin-top: 25px;
}

.joined-inputs {
    display: flex;
    gap: 20px;
    width: 100%;
}
