.episode-info-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    column-gap: 30px;
    padding: 20px;
}

.episode-info-container {
    flex: 1 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.episode-info-input-container {
    width: 100%;
}

.episode-info-checkbox-container {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100%;
}

.episode-info-date-picker-container {
    width: 100%;
    display: flex;
}

.episode-info-autocomplete-div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    height: 100%;
}
