.speakers-card-main-container {
    height: 375px;
    width: 250px;
    margin: 20px 10px;
    border-radius: 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    transition: all 0.2s ease-in-out;
    box-shadow: 6px 6px 10px 0 rgba(0, 0, 0, 0.3);
}

.speaker-card-top-div {
    background-color: burlywood;
    height: 33%;
    width: 100%;
    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;
}

.speaker-card-picture-div {
    position: absolute;
    height: 150px;
    width: 150px;
    border: 5px solid white;
    border-radius: 50%;
    top: 50px;
    right: 50px;
    overflow: hidden;
}

.speaker-card-bottom-div {
    flex: 1 1;
    width: 100%;
    padding-top: 90px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.speaker-name {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    margin-bottom: 10px;
}

.speaker-job {
    font-size: 14px;
    color: grey;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    flex: 1 1;
    max-height: 91px;
    overflow-y: auto;
}

.speaker-linkedin {
    height: 35px;
}

.speaker-linkedin > svg {
    cursor: pointer;
}

.speaker-action-area {
    height: 0;
    opacity: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    transition: all 0.2s ease-in-out;
}

.speaker-action-area > svg {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.speaker-action-area > svg:hover {
    transform: scale(1.1);
}

.speakers-card-main-container:hover .speaker-action-area {
    height: 35px;
    opacity: 1;
}

.speakers-card-main-container:hover {
    box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.4);
}