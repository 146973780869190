.views-report-panel-main-div {
    width: 100%;
    height: 100%;
    min-height: 450px;
    display: flex;
    box-shadow: 0px 5px 17px -5px rgba(0, 0, 0, 0.5)
}

.views-report-chart {
    width: 60%;
    min-width: 800px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.views-report-table {
    width: 40%;
    min-width: 400px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}