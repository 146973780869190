.alerts-container-main-div {
    width: 100%;
    height: 100%;

}

.alerts-div {
    padding: 20px;
}

.alerts-div > p {
    font-size: 16px;
    color: white;
    background-color: var(--light-blue);
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    user-select: none;
}

.alert-inner-div {
    padding: 5px 5px 5px 25px;
}

.alert-inner-div > p {
    background-color: var(--orange-soft);
    color: white;
    margin-top: 5px;
    border-radius: 10px;
    padding-left: 5px;
    max-height: 72px;
    overflow-y: auto;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

