.dashboard-main-div {
    width: 100%;
    height: calc(100vh - 80px);
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    min-width: 1200px;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--background);
}

.dashboard-top-div {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to top, var(--dark-blue), var(--light-blue));
    padding-bottom: 60px;
    box-shadow: 0 0 10px 3px rgba(0,0,0,0.5);
    width: 100%;
}

.dashboard-middle-div {
    width: 100%;
    height: 525px;
    display: flex;
    justify-content: center;
}

.dashboard-middle-right {
    width: 20%;
    max-width: 300px;
    height: 100%;
    background-color: white;
    border-radius: 15px;
    margin-top: -30px;
    box-shadow: 0px 0px 11px 2px rgba(0,0,0,0.5);
}

.dashboard-middle-left {
    width: 40%;
    height: 100%;
    margin-right: 25px;
    background-color: white;
    border-radius: 15px;
    margin-top: -30px;
    box-shadow: 0px 0px 11px 2px rgba(0,0,0,0.5);
}

.dashboard-middle-middle {
    max-width: 450px;
    flex: 1 1;
    height: 100%;
    margin-right: 25px;
    background-color: white;
    border-radius: 15px;
    margin-top: -30px;
    box-shadow: 0px 0px 11px 2px rgba(0,0,0,0.5);
}

.dashboard-card-divider {
    height: 250px !important;
    background-color: #3c4e66;
}

.dashboard-activity-main-div {
    width: 100%;
    height: 90%;
    overflow-y: auto;
}

.dashboard-users-main-div {
    width: 100%;
    height: 90%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}

.dashboard-alerts-main-div {
    width: 100%;
    height: 90%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}

.dashboard-div-title{
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    margin: 10px 0 5px 0;
    user-select: none;
}

.dashboard-activity-main-div::-webkit-scrollbar {
    width: 10px;
}

.dashboard-activity-main-div::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
    margin-bottom: 10px;
}

.dashboard-activity-main-div::-webkit-scrollbar-track:hover {
    background-color: rgba(0,0,0,0.1);
}

.dashboard-activity-main-div::-webkit-scrollbar-thumb {
    border: 2.5px solid transparent;
    border-radius: 20px;
    background-color: var(--orange-soft);
    background-clip: content-box;
}

.dashboard-activity-main-div::-webkit-scrollbar-thumb:hover{
    border: 3px solid transparent;
    background-color: var(--orange-soft);
}
