.info-dialog-buttons-div {
    width: 100%;
    height: 25%;
    display: flex;
    justify-content: flex-end;
    user-select: none;
}

.info-dialog-text-div {
    width: 100%;
    flex: 1 1;
    padding: 15px 5px;
    white-space: pre-line;
}

.info-dialog-action {
    padding: 15px 15px !important;
    background-color: var(--background);
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: fit-content;
}