* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif !important;
  /* overflow-x: hidden; */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  border: 2.5px solid transparent;
  border-radius: 20px;
  background-color: var(--orange-soft);
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  border: 3px solid transparent;
  background-color: var(--orange-soft);
}

:root {
  --pink: #ff0082;
  --orange: #ff5c00;
  --base1: #7A2180;
  --base2: #E40276;
  --black: #000000;
  --purple: #221A2A;
  --light-purple: #362c40;
  --dark-blue: #0e1116;
  --light-blue: #333f55;
  --background: #f6f7fd;
  --light-red: #f37572;
  --light-green: #5cb660;
  --light-yellow: #ffb54b;
  --pink-soft: #B14282;
  --orange-soft: #E8954E;
}