.user-info-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
}

.user-info-left-container {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.user-info-input-container {
    width: 100%;
    margin: 15px 0;
    display: flex;
    justify-content: space-between;
}

.user-info-date-picker-container {
    margin-top: 25px;
    width: 100%;
    display: flex;
}

.user-info-autocomplete-div {
    margin-top: 20px;
    width: 80%;
}
