.about-main-div {
    width: 100%;
    height: fit-content;
    min-height: 900px;
    display: flex;
    margin-top: 50px;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.about-table-div {
    width: 50%;
    min-width: 500px;
    display: flex;
    justify-content: center;
}

.about-section-preview {
    width: 100%;
    /* height: 600px; */
    min-height: 500px;
    min-width: 700px;
    border: 2px dashed var(--light-blue);
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
}

.section-div {
    height: 600px;
    width: 100%;
    overflow-y: auto;
    padding: 100px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-text-image-right {
    width: 30%;
    min-width: 400px;
    margin-right: 8vw;
}

.section-text-image-left {
    width: 30%;
    min-width: 400px;
    margin-left: 8vw;
}

.section-text-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 25px;
}

.section-text-content {
    font-size: 16px;
    font-weight: 400;
    white-space: pre-wrap;
}

.section-img {
    width: 315px;
    height: 375px;
}

.section-text {
    width: 80%;
    max-width: 1000px;
    margin-right: 0;
}

.about-image {
    width: 320px;
    height: 380px;
    object-fit: cover;
    /* object-position: -450px 0px; */
}

.text-link {
    text-decoration: underline;
    color: cornflowerblue;
    cursor: pointer;
}