.speaker-more-info-dialog-content {
    height: 400px;
    width: 600px;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
}

.speaker-more-info-dialog-header {
    height: 10%;
    padding: 0 20px;
    background-image: linear-gradient(to bottom, var(--light-blue) 0%, var(--dark-blue) 100%);
    display: flex;
}

.speaker-more-info-dialog-info-wrapper {
    height: 90%;
    display: flex;
    align-items: center;
    background-color: var(--background);
}

.speaker-more-info-dialog-picture-div {
    height: 100%;
    width: 45%;
    padding: 10px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.speaker-more-info-dialog-picture {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 10px;
    filter: drop-shadow(2px 4px 6px black);
}

.speaker-dialog-title {
    flex: 1 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.speaker-dialog-title > p {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.speaker-dialog-actions {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: white;
}

.speaker-dialog-actions > * {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.speaker-dialog-actions > *:hover {
    transform: scale(1.1);
}

.speaker-dialog-chips {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
}

.speaker-list-item-div {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 50px;
}

.speaker-more-info-chips-div {
    display: flex;
    justify-content: space-around;
    width: 100%;
    user-select: none;
    padding: 20px;
}

.speaker-more-info-other-info {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    color: rgba(0, 0, 0, 0.6);
}

.speaker-more-info-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    height: 100%;
}

