.speakers-images-upload-container {
    height: 100%;
    display: flex;
    align-items: center;
}

.speakers-images-portrait-upload-div {
    padding: 0 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.speakers-landscape-image-place {
    width: 400px;
    height: 160px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.speakers-landscape-image-preview {
    object-fit: contain;
}

.speakers-image-div-title {
    font-size: 20px;
    text-align: center;
}

.speakers-image-div-subtitle {
    text-align: center;
    font-size: 14px;
}

.speakers-image-info-div {
    width: 100%;
    font-size: 14px;
    text-align: center;
}

.speakers-remove-image-btn {
    width: 300px;
    height: 20px;
    border: none;
    border-radius: 10px;
    background-color: var(--light-blue);
    color: white;
    transition: width 0.3s ease-in-out;
    cursor:pointer
}

.speakers-remove-image-btn:hover {
    width: 325px
}