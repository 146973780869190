.topics-more-info-dialog-content {
    height: 400px;
    width: 600px;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
}

.topics-more-info-dialog-header {
    height: 10%;
    min-height: 60px;
    padding: 0 20px;
    background-image: linear-gradient(to bottom, var(--light-blue) 0%, var(--dark-blue) 100%);
    display: flex;
}

.topics-more-info-dialog-info-wrapper {
    height: 90%;
    display: flex;
    align-items: center;
    background-color: var(--background);
}

.topics-more-info-dialog-picture-div {
    height: 100%;
    width: 45%;
    padding: 10px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.topics-more-info-dialog-picture {
    height: 60%;
    overflow: hidden;
    margin-bottom: 10px;
    filter: drop-shadow(2px 4px 6px black);
}

.topics-dialog-title {
    flex: 1 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.topics-dialog-actions {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: white;
}

.topics-dialog-actions > * {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.topics-dialog-actions > *:hover {
    transform: scale(1.1);
}

.topics-dialog-chips {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
}

.topics-list-item-div {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 50px;
}

.topics-more-info-chips-div {
    display: flex;
    justify-content: space-around;
    width: 100%;
    user-select: none;
    padding: 20px;
}

.topics-more-info-other-info {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    color: rgba(0, 0, 0, 0.6);
}

.topics-more-info-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    height: 100%;
}

.topics-mui-dialog-container > .MuiDialog-container > .MuiPaper-root {
    border-radius: 20px;
    background-color: transparent;
}