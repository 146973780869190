.responsible-div {
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 150px;
}

.responsible-div > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.url-component {
    color: var(--primary);
    text-decoration: underline;
    cursor: pointer;
}


.status-component-div {
    width: 90%;
    display: flex;
}

.status-component-div span {
    line-height: 24px;
}

.title-component {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
}