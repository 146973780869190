.users-card-main-container {
    height: 375px;
    width: 250px;
    margin: 20px 10px;
    border-radius: 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    position: relative;
    transition: all 0.2s ease-in-out;
    box-shadow: 6px 6px 10px 0 rgba(0, 0, 0, 0.3);
}

.users-card-top-div {
    background-color: burlywood;
    height: 33%;
    width: 100%;
    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 20px;
}

.users-card-picture-div {
    position: absolute;
    height: 150px;
    width: 150px;
    border: 5px solid white;
    border-radius: 50%;
    top: 50px;
    right: 50px;
    overflow: hidden;
}

.users-card-bottom-div {
    flex: 1 1;
    width: 100%;
    padding-top: 90px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.users-name {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    margin-bottom: 10px;
}

.users-job {
    font-size: 14px;
    color: grey;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    word-break: break-word;
    padding: 0 15px;
}

.users-last-login {
    font-size: 14px;
    color: grey;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    flex: 1 1;
}

.users-linkedin {
    height: 35px;
}

.users-linkedin > svg {
    cursor: pointer;
}

.users-action-area {
    height: 0;
    opacity: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    transition: all 0.2s ease-in-out;
}

.users-action-area > svg {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.users-action-area > svg:hover {
    transform: scale(1.1);
}

.users-card-main-container:hover .users-action-area {
    height: 35px;
    opacity: 1;
}

.users-card-main-container:hover {
    box-shadow: 6px 6px 5px 0px rgba(0, 0, 0, 0.4);
}

.logged-in-div {
    position: absolute;
    height: 25px;
    width: 25px;
    background-color: green;
    top: 60px;
    right: 60px;
    border-radius: 50%;
    z-index: 20;
}

.userType-div-user-card {
    width: fit-content;
    padding: 0 10px;
    height: 20px;
    border-radius: 10px;
    background-color: var(--light-blue);
    text-align: center;
    color: white;
    line-height: 20px;
    word-break: break-all;
    margin: 0 10px;
}

.userType-wrapper-div {
    position: absolute;
    width: 100%;
    top: 185px;
    display: flex;
    justify-content: center;
    z-index: 20;
    overflow: hidden;
}